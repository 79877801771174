<template lang="pug">
  .admin
    .h1
      |  Настройки
    .catalog-list
      .item(:class="{'active':currentCatalog === 'obreshotka'}" @click="currentCatalog='obreshotka'")
        | Обрешеточный
      .item(:class="{'active':currentCatalog === 'sandwich'}" @click="currentCatalog='sandwich'")
        | Сендвич-панельный
      .item(:class="{'active':currentCatalog === 'common'}" @click="currentCatalog='common'")
        | Общие

    div.settings
      .settings-panel-left

        .catalog
          .catalog-item(v-for="(item, index) in catalog" :key="index" @click="currentCatalogIndex=index" :class="{active:currentCatalogIndex === index}")
            | {{item.title}}

      .settings-panel-right(v-if="currentCatalogIndex !== null")
          hint-for-edit(v-if="catalog[currentCatalogIndex].key === 'hints'" :folder.sync="catalog[currentCatalogIndex]" :path="localPath" @update="onUpdate")
          block-for-edit(v-else :folder.sync="catalog[currentCatalogIndex]" :path="localPath" @update="onUpdate")


</template>

<script>
import EquipmentCategories from '@/common/EquipmentCategories';
import BlockForEdit from '@/components/Admin/Settings/BlockForEdit';
import HintForEdit from '@/components/Admin/Settings/HintForEdit';
import api from '@/common/http/api';

export default {
  name: "Settings",
  components: {BlockForEdit, HintForEdit},
  data() {
      return {
        currentCatalog: 'obreshotka',
        localPath: 0,
        catalogFull: null,
        catalog: null,
        currentCatalogIndex: null
      }
  },
  created() {
    this.catalogFull = EquipmentCategories.getAll()
    this.catalog = this.catalogFull[this.currentCatalog]
  },
  watch: {
    currentCatalog (val) {
      this.catalog = this.catalogFull[val]
      this.currentCatalogIndex = null
    },
    currentCatalogIndex (val) {
      // console.log(this.catalog[val].key)
    }
  },
  computed: {
  },
  methods: {
    onUpdate () {
      console.log(this.catalog)
      api.post('/category/save', {
        catalog: this.currentCatalog,
        data: this.catalog
      })
      .then(function (response) {
        console.log(response);
      })
    },
  }
}
</script>

<style scoped>

</style>
